.talking-head {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &__message {
    display: none;
    padding-left: 20%;
    padding-right: 5%;
  }

  &__messages {
    // padding-top: 5%;
    position: relative;
    font-size: calc(2.8 * var(--vh));
    line-height: calc(3.2 * var(--vh));
    font-family: var(--font1);
    font-weight: 500;

    svg {
      height: auto;
      width: 100%;
    }

    &-text {
      position: absolute;
      left: 0;
      top: 5%;
      width: 100%;
      height: 71%;
      display: flex;
      align-items: center;
      overflow: hidden;
    }
  }

  &__player {
    height: 59%;
    overflow: hidden;
    position: relative;
  }

  &__play-button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 111;

    &>span {
      width: calc(10 * var(--vh));
      height: calc(10 * var(--vh));
      background-color: rgba(#ffffff, 0.6);
      border-radius: 50%;
      position: relative;
      // left: 50%;
      // top: 48%;
      // transform: translate(-50%, -50%);
      visibility: 1;
      opacity: 1;
      transition: 0.3s;
      z-index: 1;
    }

    &[data-state="play"] {
      visibility: 0;
      opacity: 0;
    }

    &[data-state="refresh"] {
      .video-player__refresh-icon {
        display: block;
      }

      .video-player__play-icon {
        display: none;
      }
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 32%;
      height: auto;
    }
  }

  &__play-icon {
    left: 53% !important;
  }

  &__refresh-icon {
    width: 40% !important;
    display: none;
  }

  :global {
    .plyr {
      height: 102%;
      margin-top: -1%;
    }

    .plyr--video,
    .plyr__video-wrapper {
      background-color: transparent;
    }
  }
}