.statistic {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__heading {
    padding-top: 5%;
    text-align: center;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 0;
    flex-wrap: wrap;

    button {
      width: 48%;
    }
  }

  &__button-bottom {
    width: 100%;
    margin-top: 10px;
  }

  &__cards {
    height: 65%;
    overflow: auto;

    & > div {
      margin-bottom: 4%;
    }
  }
}
