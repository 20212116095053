.congratulations {
  z-index: 11;
  position: relative;
  text-align: center;
  padding-top: calc(4 * var(--vh)) !important;

  &__heading {
    margin-bottom: calc(3 * var(--vh));
  }

  &__buttons {
    button {
      margin-bottom: calc(2 * var(--vh));
    }
  }

  &__image {
    height: calc(20 * var(--vh));
    margin-bottom: calc(2 * var(--vh));
    animation-name: animationImage;
    animation-duration: 2.5s;

    @keyframes animationImage {
      0% {
        transform: rotate(0);
      }

      25% {
        transform: rotate(-25deg);
      }

      // 50% {transform: rotate(0);}
      75% {
        transform: rotate(25deg);
      }

      100% {
        transform: rotate(0);
      }
    }
  }

  &__count {
    font-size: calc(2.2 * var(--vh));
    font-weight: bold;
    color: var(--green);
  }

  &__description {
    font-size: calc(2.2 * var(--vh));
    line-height: calc(2.8 * var(--vh));
    font-weight: bold;
    margin-bottom: calc(4 * var(--vh));
  }

  p {
    margin-bottom: 0 !important;
  }


}