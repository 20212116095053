$root: ".chapter-steps";

#{$root} {
  height: 100%;
  display: flex;
  align-items: center;

  &__wrapper {
    max-height: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    overflow: auto;
    width: 100%;
  }

  &__item {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      left: 50%;
      transform: translateX(-50%);
      bottom: 50%;
      height: 70%;
      width: 6px;
      background-color: #c4c4c4;
      z-index: 0;
    }

    &:last-of-type {
      margin-bottom: 20%;
    }

    &:first-of-type {
      padding-top: 0;

      &::before {
        display: none;
      }
    }

    &--unlocked {
      padding-top: calc(2 * var(--vh));

      #{$root}__item-icon {
        animation-name: circleRotate;
        animation-duration: 4s;
        animation-iteration-count: infinite;
      }
    }

    &--locked {
      padding-top: calc(2 * var(--vh));

      #{$root}__item-icon {
        height: calc(9 * var(--vh)) !important;
        width: calc(9 * var(--vh)) !important;
      }
    }

    &--animation {
      padding-top: calc(2 * var(--vh));

      #{$root}__item-icon {
        animation-name: goToNextScale;
        animation-delay: 0.5s;
        animation-duration: 2s;
        animation-fill-mode: forwards;

        circle,
        path {
          animation-name: goToNextColor;
          animation-delay: 0.5s;
          animation-duration: 1s;
          animation-fill-mode: forwards;
        }
      }

      button span {
        animation-name: goToNextNumber;
        animation-duration: 3s;
        animation-fill-mode: forwards;
      }

      &::before {
        animation-name: goToNextLine;
        animation-delay: 0.5s;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }

      @keyframes goToNextScale {
        to {
          height: calc(9 * var(--vh));
          width: calc(9 * var(--vh));
          transform: rotate(90deg);
        }
      }

      @keyframes goToNextColor {
        from {
          fill: var(--yellow);
        }

        to {
          fill: var(--green);
        }
      }

      @keyframes goToOpenStep {
        to {
          fill: var(--yellow);
        }
      }

      @keyframes goToNextNumber {
        to {
          font-size: calc(4 * var(--vh));
        }
      }

      @keyframes goToNextLine {
        to {
          opacity: 0.5;
          background-color: var(--green);
        }
      }
    }

    &--animation-locked {
      #{$root}__item-icon {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        height: calc(14 * var(--vh)) !important;
        width: calc(14 * var(--vh)) !important;
        animation-name: showPassedStep;
        animation-delay: 2s;
        animation-duration: 1s;
        animation-fill-mode: forwards;

        &--animatiom-locked {
          position: static;
          opacity: 1;
          height: calc(9 * var(--vh)) !important;
          width: calc(9 * var(--vh)) !important;
          animation-name: hideLockedIcon;
          animation-delay: 2s;
          animation-duration: 0, 5s;
          animation-fill-mode: forwards;

          circle,
          path {
            animation-name: goToOpenStep;
            animation-delay: 2s;
            animation-duration: 1s;
            animation-fill-mode: forwards;
          }
        }
      }

      #{$root}__item-number {
        opacity: 0;
        animation-name: showNumber;
        animation-delay: 2s;
        animation-duration: 1s;
        animation-fill-mode: forwards;
      }

      @keyframes showPassedStep {
        from {
          transform: rotate(0) scale(0.2);
          opacity: 0;
        }

        to {
          transform: rotate(90deg) scale(1);
          opacity: 1;
        }
      }

      @keyframes hideLockedIcon {
        to {
          opacity: 0;
          transform: scale(1.5);
        }
      }

      @keyframes showNumber {
        to {
          opacity: 1;
        }
      }

      // @keyframes goToNextColor {
      //   from{
      //     fill: var(--yellow);
      //   }
      //   to{
      //     fill: var(--green);
      //   }
      // }

      // @keyframes goToNextNumber {
      //   to{
      //     font-size: calc(4 * var(--vh));
      //   }
      // }

      // @keyframes goToNextLine {
      //   to{
      //     opacity: 0.5;
      //     background-color: var(--green);
      //   }
      // }
    }

    &--passed {
      padding-top: calc(2 * var(--vh));

      #{$root}__item-icon {
        height: calc(9 * var(--vh)) !important;
        width: calc(9 * var(--vh)) !important;

        circle,
        path {
          fill: var(--green);
        }
      }

      button span {
        font-size: calc(4 * var(--vh));
      }

      &::before {
        opacity: 0.5;
        background-color: var(--green);
      }
    }

    &-button {
      position: relative;
      margin-left: auto;
      margin-right: auto;
      z-index: 1;
      padding: 0;
      border: none;
      outline: none;
      background: none;
      height: calc(14 * var(--vh));
      width: calc(14 * var(--vh));
    }

    &-number {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: bold;
      font-size: calc(6 * var(--vh));
      line-height: 1;
      color: #ffffff;
      font-family: var(--font1);
    }

    &-icon {
      height: calc(14 * var(--vh));
      width: calc(14 * var(--vh));
    }
  }

  @keyframes circleRotate {
    0% {
      transform: rotate(0);
    }

    50% {
      transform: rotate(0);
    }

    70% {
      transform: rotate(90deg);
    }

    100% {
      transform: rotate(0);
    }
  }
}