.game-modal {
  &__title {
    padding-top: 20%;
  }

  &__image {
    margin-top: 5%;

    svg {
      width: 50%;
      height: auto;
    }
  }
}