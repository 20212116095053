.results-screen {
  z-index: 2;
  position: relative;
  // padding-top: calc(5 * var(--vh));

  &__heading {
    text-align: center;
    padding-top: 5%;
  }

  &__description {
    overflow: auto;
    font-size: calc(3.2 * var(--vh));
    line-height: calc(4 * var(--vh));
    font-family: var(--font1);
    font-weight: 500;
    text-align: center;
  }

  &__container {
    position: relative;
    z-index: 1;
  }

  @keyframes showText {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

}

.progress-loader {
  margin: 20% 0;

  svg {
    width: calc(19 * var(--vh));
  }

  text-align: center;

  &__background,
  &__progress {
    fill: none;
  }

  &__background {
    stroke: #fff;
  }

  &__progress {
    stroke: var(--green);
    stroke-linejoin: round;

    animation: dash 1.5s ease;
    animation-fill-mode: forwards;

    @keyframes dash {
      from {
        stroke-dashoffset: 455.531;
      }
    }
  }

  &__text {
    font-size: calc(5 * var(--vh));
    fill: var(--green);
    font-weight: bold;
    font-family: var(--font1);
  }
}