.rotate-placeholder {
  background-color: var(--light-green);
  height: 100%;
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    transform: rotate(90deg);
    height: 30%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__heading {
    z-index: 1;
    color: #fff;
    font-size: 30px;
    margin-bottom: 20px;
    line-height: 1.4;
    font-weight: bold;
    font-family: var(--font1);
  }

  &__icon {
    height: 50%;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    fill: #fff;
  }
}
