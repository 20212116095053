.statistic-panel {
  background-color: #fff;
  border: 2px solid var(--green);
  padding: 2%;

  &__title {
    margin-bottom: 1%;
    font-family: var(--font1);
    display: flex;
    justify-content: space-between;

    span:nth-child(2) {
      color: var(--green);
    }
  }

  &__value {
    margin-bottom: 1%;
    background-color: #EFF7F2;
    height: calc(2.5 * var(--vh));
    padding: 1%;
    position: relative;

    &-progress {
      background-color: var(--green);
      height: 100%;

      animation: width 1.5s ease;
      animation-fill-mode: forwards;

      @keyframes width {
        from {
          width: 0;
        }
      }
    }

    &-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      justify-content: space-between;

      span {
        width: calc(0.2 * var(--vh));
        height: 100%;
        display: flex;
        background-color: #EFF7F2;
      }
    }
  }
}