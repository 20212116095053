.audio-player {
  height: 50%;
  // background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  // padding-top: calc(3.5 * var(--vh));

  &__heading {
    width: 100%;
    margin-top: calc(4 * var(--vh));
    height: calc(9 * var(--vh));
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    border-bottom: 0.4vh solid var(--red);
  }

  &__player {
    margin-top: auto;
    margin-bottom: 0;

    &>div {
      position: static;
    }
  }

  button[data-plyr="play"] {
    position: absolute;
    // margin-top: calc(1.5 * var(--vh));
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(12 * var(--vh));
    height: calc(12 * var(--vh));
    background-color: var(--green) !important;
    border-radius: 50%;
    padding: 0;

    svg {
      width: calc(4 * var(--vh));
      height: calc(4 * var(--vh));
      fill: #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &:before {
      // content: '';
      // position: absolute;
      // left: 50%;
      // top: 50%;
      // width: 100%;
      // height: 100%;
      // transform: translate(-50%, -50%);
      // border-radius: 50%;
      // opacity: 0;
      // transition: 0.3s;
      // border: 0 solid rgba(#00671F, 0.15);
    }

    &[aria-label="Pause"]:before {
      border-width: calc(4 * var(--vh));
      opacity: 1;
      width: calc(100% + 8vh);
      height: calc(100% + 8vh);
    }
  }

  :global {
    .plyr__controls {
      flex-wrap: wrap;
      justify-content: center;
      padding: 0;
      background-color: transparent !important;
      // margin-bottom: calc(3 * var(--vh));

      * {
        font-size: calc(2 * var(--vh));
      }

      input[type="range"]::-webkit-slider-thumb {
        background: var(--green);
        width: calc(2.8 * var(--vh));
        height: calc(2.8 * var(--vh));
        margin-top: calc(-1.15 * var(--vh));
      }
    }

    .plyr__progress__container {
      width: 100%;
      order: 1;
      flex: none;
    }

    .plyr__time--duration {
      display: block !important;
      color: #828282;
    }

    .plyr__time--current {
      color: var(--green);
      font-weight: 600;
    }

    .plyr__time {
      margin-top: calc(-6 * var(--vh));
    }
  }

  &__viz {
    background-color: rgba(#00671f, 0.15);
    position: absolute !important;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 50%;
    border-radius: 50%;
    z-index: -1;
    // transition: 0.2s;
  }
}