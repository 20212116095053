.game-quiz {
  height: 100%;
  background-color: var(--light-green);

  &__answers {
    position: relative;
    // padding-top: 20px;
    padding-top: calc(3 * var(--vh));

    &-point {
      // font-size: 26px;
      font-size: calc(3.2 * var(--vh));
      line-height: 1;
      font-weight: 400;
      font-family: Source Serif Pro;
      text-transform: uppercase;
      // margin-right: 20px;
      margin-right: 5vw;
    }

    &-list {
      // padding-top: 5px;
      display: block;

      &--animation {
        animation: hideAnswersList 1s cubic-bezier(0.66, -0.49, 0.36, 1.53);
        animation-fill-mode: forwards;

        @keyframes hideAnswersList {
          to {
            opacity: 0;
            transform: translateY(50vh);
          }
        }
      }
    }
  }

  &__landscape {
    position: fixed;
    // bottom: calc(5 * var(--vh));
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }

  &__zapfen {
    position: absolute;
    right: 4vw;
    top: 0;
    width: auto;
    height: calc(8 * var(--vh));
  }

  &__hinweis {
    display: flex;
    align-items: center;
    margin-bottom: calc(3 * var(--vh));
    justify-content: center;

    svg {
      margin-right: 5px;
    }

    span {
      text-decoration: underline;
    }
  }

  &__label-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: calc(1.5 * var(--vh));
    position: relative;
    z-index: 1;
    color: #00671f;
  }

  &__checkbox {
    position: relative;
    // margin-bottom: 10px;
    margin-bottom: calc(1.2 * var(--vh));

    input {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;

      &:checked + label:before {
        border-width: 0.4vh;
      }
    }

    label {
      position: relative;
      margin-bottom: 0;
      width: 100%;
      display: block;

      &:before {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        border: solid 0.2vh #00671f;
        transition: 0.3s;
      }
    }

    &--right {
      label {
        color: #fff;

        &:before {
          border-width: 0.2vh !important;
          background-color: rgba(0, 103, 31, 0.2);
        }
      }
    }

    &--wrong {
      label {
        & > div {
          color: #fff;
        }

        &:before {
          border: solid 0.2vh rgba(203, 11, 14, 0.5) !important;
          background-color: rgba(203, 11, 14, 0.5);
        }
      }
    }
  }
}
