$root: ".chapter-cards";

#{$root} {
  // padding-top: calc(2 * var(--vh));
  // position: relative;
  align-items: center;
  display: flex;
  height: calc(93 * var(--vh));
  justify-content: center;
  overflow: hidden;

  &__item {
    padding: calc(2 * var(--vh));
    height: calc(100% - (4 * var(--vh)));
    display: flex;
    flex-direction: column;
  }

  &__name {
    margin-bottom: calc(2 * var(--vh));
    height: calc(5 * var(--vh));
    overflow: hidden;
  }

  &__title {
    height: calc(12 * var(--vh));
    overflow: hidden;
    line-height: calc(4.1 * var(--vh)) !important;
  }

  &__picture {
    margin-bottom: calc(2 * var(--vh));
    width: 100%;
    height: calc(22 * var(--vh));
    object-fit: cover;
    object-position: center center;
  }

  &__description {
    margin-bottom: calc(5 * var(--vh));
    height: calc(7.5 * var(--vh));
    overflow: hidden;
  }

  &__blocked-content {
    height: calc(20 * var(--vh));
    width: auto;
    display: block;
    padding: calc(8 * var(--vh));
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    display: flex;
    align-items: center;

    svg {
      width: auto;
      height: calc(3 * var(--vh));
      margin-left: calc(2 * var(--vh));
    }

    &-blocked {
      display: flex;
      align-items: flex-start;
      justify-content: center;

      h2 {
        font-size: calc(3.5 * var(--vh)) !important;
        margin-bottom: 0;
      }

      svg {
        width: auto;
        height: calc(3 * var(--vh));
        margin-left: calc(2 * var(--vh));
        margin-top: 4px;
      }
    }
  }

  &__items {
    position: relative;
    width: 100%;
    // height: 60%;
    height: 100%;
    display: flex;
    align-items: center;

    // margin-top: calc(-7.5 * var(--vh));
    &>div {
      // top: 50%;
      height: calc(60 * var(--vh));
      background-color: #fff;
      box-shadow: 0px 0px 10px rgba(145, 143, 143, 0.25);
      border-radius: calc(1 * var(--vh));
      // margin-top: calc(-30 * var(--vh));

      animation: showCard 0.7s;
      animation-fill-mode: forwards;

      // &[data-passed-card="true"] {
      //   height: calc(60 * var(--vh));
      // }

      @keyframes showCard {
        from {
          margin-top: 5vh;
          opacity: 0;
        }

        to {
          margin-top: 0;
          opacity: 1;
        }
      }
    }
  }

  &__content {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 0;
      margin-top: auto;
    }

    &[data-passed="true"] {
      button {
        display: none;
      }
    }

    &[data-passed="true"] {
      #{$root}__passed-title {
        display: block;
      }
    }
  }

  &__passed-title {
    position: relative;
    margin-bottom: 0;
    text-align: center;
    font-size: calc(3.5 * var(--vh)) !important;
    top: calc(3.2 * var(--vh));
    display: none;
  }

  // .app-animation{

  //   &__cards {
  //     height: 265px;
  //     position: relative;
  //     width: 350px;
  //   }
  // }
}