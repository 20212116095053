.speech-bubble {
  display: flex;
  align-items: center;

  &>div {
    width: 100%;
  }


  &__message {
    position: relative;
    margin-bottom: 40px;

    &-icon {
      &-top {
        position: absolute;
        left: 0;
        bottom: 100%;
        display: block;
      }

      &-bottom {
        position: absolute;
        right: 40px;
        top: 100%;
        display: block;
      }

      &-triangle {
        position: absolute;
        top: calc(100% - 2px);
        right: 60px;
        width: 40px;
        height: 40px;

        &:before {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          z-index: 1;
          border-style: solid;
          border-width: 40px 40px 0 0;
          border-color: #000000 transparent transparent transparent;
          top: 0;
          left: 50%;
          margin-left: -20px;
        }

        &:after {
          content: '';
          position: absolute;
          display: block;
          width: 0;
          z-index: 1;
          border-style: solid;
          border-width: 36px 36px 0 0;
          border-color: #fff transparent transparent transparent;
          top: -1px;
          left: 50%;
          margin-left: -18px;
        }
      }
    }



    &-body {
      position: relative;

      padding: 3% 5% 4%;

      background-color: #ffffff;
      border: 2px solid #000000;

      &>div {
        position: relative;
        z-index: 2;
        font-size: calc(2.5 * var(--vh));
        line-height: calc(3 * var(--vh));
      }
    }
  }

}