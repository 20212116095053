.video-player {
  --plyr-color-main: var(--red);

  $height_panel: 6vh;
  $height_range: 1vh;

  // background-color: #000;
  position: relative;
  // padding-top: calc(3.5 * var(--vh));

  &__heading {
    width: 100%;
    padding-top: calc(4 * var(--vh));
    height: calc(9 * var(--vh));
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    color: #fff;
    border-bottom: 0.4vh solid #fff;
  }

  &__zapfen {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    height: calc(25 * var(--vh));
    width: auto;
    opacity: 0.15;
  }

  &__player {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    left: 0;
    height: 100%;
  }

  &__play-button {
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    width: 100%;
    height: calc(100% - #{$height_panel + $height_range});
    //height: calc((100% - (#{$height_panel} + #{$height_range} )) * var(--vh));
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 111;

    &>span {
      width: calc(10 * var(--vh));
      height: calc(10 * var(--vh));
      background-color: rgba(#ffffff, 0.6);
      border-radius: 50%;
      position: relative;
      // left: 50%;
      // top: 48%;
      // transform: translate(-50%, -50%);
      visibility: 1;
      opacity: 1;
      transition: 0.3s;
      z-index: 1;
    }

    &[data-state="play"] {
      visibility: 0;
      opacity: 0;
    }

    &[data-state="refresh"] {
      .video-player__refresh-icon {
        display: block;
      }

      .video-player__play-icon {
        display: none;
      }
    }

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 32%;
      height: auto;
    }
  }

  &__play-icon {
    left: 53% !important;
  }

  &__refresh-icon {
    width: 40% !important;
    display: none;
  }

  :global {
    .plyr--video .plyr__controls {
      padding: 0;
      background: transparent;
      background-color: #4f4f4f;
      // bottom: -32px;
      transform: none !important;
      opacity: 1 !important;
      height: $height_panel;
      justify-content: flex-start;

      button:hover {
        background-color: transparent !important;
      }
    }

    button[data-plyr] {
      height: 80%;
    }

    .plyr__time--current {
      padding-right: 10px;
    }

    [data-plyr="fullscreen"] {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }

      margin-right: 0;
      margin-left: auto;
      border-left: 1px solid #828282;
    }

    [data-plyr="play"] {
      border-right: 1px solid #828282;
    }

    .plyr {
      position: static;
      height: 100%;
    }

    .plyr--video {
      overflow: visible;

    }

    .plyr__progress {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      top: -$height_range;
      padding: 0;
      margin: 0;
      margin-right: 0;

      .plyr__progress__buffer {
        height: $height_range;
        margin-top: 0;
        transform: translateY(-50%);
      }

      input[type="range"] {
        width: 100%;
        margin: 0 !important;
        height: $height_range;

        &::-webkit-slider-thumb {
          // display: none;
          opacity: 0;
        }

        &::-webkit-slider-runnable-track {
          height: $height_range;
          border-radius: 0;
        }
      }
    }

    .plyr__time--duration {
      display: block !important;
    }
  }
}