.button {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    padding: 0 10px;
    border-radius: 3px;
  }

  &>div {
    width: max-content;
  }
}